<template>
    <mct-lista-padrao ref="childComponent" :source="source" :titulo="titulo" :showModal="showModal" @setData="setData">
        <div class="mct-grid">
            <div class="mct-grid-scroll">         
                <table class="table table-striped table-bordered table-sm table-hover">
                    <thead>                       
                        <tr>
                            <th class="mct-acoes col-xs-2">Ações</th>
                            <th v-for="(column, index) in colunas"  @click.prevent.stop="toggleOrder($event,column.nome)" :key="index">
                                <span :style="`width: ${column.tam}; display: block`">
                                    <span>{{column.titulo}}</span>
                                    <span class="dv-table-column" v-if="column.nome === query.column">
                                        <span v-if="query.direction === 'desc'">&darr;</span>
                                        <span v-else>&uarr;</span>
                                    </span>
                                </span>
                            </th>                        
                        </tr>

                        <tr>
                            <th class="mct-acoes col-xs-2"></th>
                            <th v-for="(column, index) in colunas" :key="index">
                                <mct-filtro-campo :column="column" :source="source"/>
                                <!--<input v-if="column.filtro" type="text" style="width: 100%; display: block" @keydown.enter.prevent="onFilter(column.nome)"/>-->
                            </th>                        
                        </tr>                        
                    </thead>
                    <tbody>
                    <tr v-for="(row,index) in data.data" :key="index">
                        <td class="is-icon col-xs-2">
                            <div class="btn-group">
                                <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" v-show="!showModal"  title="Editar Registro" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.curr_codigo}/${row.curr_ent}/${row.curr_esc}/${row.curr_ano}/editar`)"></i>
                                <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" v-show="!showModal"  title="Excluir Registro" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.curr_codigo}/${row.curr_ent}/${row.curr_esc}/${row.curr_ano}/deletar`)"></i>
                                <i class="fa fa-search text-white bg-secondary mct-fa" aria-hidden="true" v-show="!showModal" title="Visualizar Registro" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.curr_codigo}/${row.curr_ent}/${row.curr_esc}/${row.curr_ano}/visualizar`)"></i>
                                <i class="fa fa-hand-pointer-o text-white bg-info mct-fa" aria-hidden="true" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"></i>
                            </div>
                        </td>
                        <td v-for="(column, key) in colunas" :key="key">{{ column.subtab ? row[column.subtab] != null ? row[column.subtab][column.nome] : '' : row[column.nome] }}</td>                                            
                    </tr>
                    </tbody>
                </table>
            </div>         
        </div> 
    </mct-lista-padrao>      
</template>
<script>

import Vue from 'vue'
import axios from '@/http'

import MctListaPadrao from '@/components/comum/MctDataViewer.vue'
import MctFiltroCampo from '@/components/comum/MctFiltroCampo.vue'

export default {
    name: 'MatrizCurricularLista',
    components: {
        MctListaPadrao,
        MctFiltroCampo
    },
    props: ['source', 'titulo', 'showModal'],
    data (){
        return {
            data: {},
            columns: {},
            query: {},
            colunas: [
                {nome: 'ser_serie', titulo: 'ANO/SÉR.', classe: '1', tam: '30px', subtab: 'serie'},
                {nome: 'ser_descricao', titulo: 'DESC. ANO/SÉRIE', classe: '1', tam: '60px', subtab: 'serie'},
                {nome: 'cur_nome', titulo: 'CURSO', classe: '4', tam: '100px', subtab: 'curso', filtro: true},
                {nome: 'turn_descricao', titulo: 'TURNO', classe: '1', tam: '30px', subtab: 'turno'},
            ]
        }
    },
    methods: {
        toggleOrder(ev, column) {
            ev.preventDefault()
            this.$refs.childComponent.toggleOrder(column);
        },
        setDescricao (ev, data ){
            ev.preventDefault()
            this.$emit('setDescricao', data)
        },
        setData (data, columns, query){
            this.data = data
            this.columns = columns
            this.query = query
        },
        redirect (ev, link) {
            ev.preventDefault();
            
            this.$router.push(link)
        },        
        getIndexes (row) {
            var indexes = ''
            if (typeof this.id === 'object'){
               for (var index in this.id) {               
                    if (indexes === ''){
                        indexes = row[this.id[0]]
                    } else {    
                        indexes = indexes + '/' + row[this.id[index]]
                    }
               }
            } else {
                indexes = row[this.id]   
            }
            return indexes
        },
        onFilter (campo){
            this.$emit('getData')               
        }

    }
}
</script>

<style scoped>

    .mct-acoes{
        width: 95px;
    }

</style>


